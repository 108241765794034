export default (text, maxLength = 15) => {
  const words = text.split(/\s/)

  if (text.length < maxLength || words.length <= 3) return text

  const output = []

  while (words.length > 1) {
    const word = words.pop()

    if (output.join(" ").length + word.length < maxLength) {
      output.unshift(word)
    } else {
      words.push(word)
      break
    }
  }

  return [...words, output.join("\u00a0")].join(" ")
}
