import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import imageProptypes from "lib/imageProptypes"
import preventWidows from "lib/preventWidows"
import Wordmark from "images/Wordmark.svg"
import theme from "theme"

const HeroLego = ({ mode, title, copy, background, children }) => (
  <section
    css={{
      position: "relative",
    }}
  >
    <SanityImage
      {...background}
      width={1440}
      alt=""
      css={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
        zIndex: 1,
      }}
    />

    <div
      css={{
        position: "relative",
        zIndex: 3,
        margin: "0 auto",
        padding: "100px 30px",
        minHeight: "max(40vw, 300px, min(70vh, 800px))",
        color: theme.tan,
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: mode === "standard" ? "flex-end" : "space-between",
        alignItems: "center",
        overflow: "hidden",
        ":after": {
          content: "''",
          position: "absolute",
          top: "calc(100% - 50px)",
          width: 2,
          height: 100,
          background: theme.tan,
          zIndex: 2,
        },
        [theme.mobile]: {
          padding: "80px 30px",
        },
      }}
    >
      {mode === "logo" && (
        <>
          {/* This <div /> makes Wordmark center vertically (flex space-between) */}
          <div />

          <Wordmark
            css={{
              display: "block",
              width: 500,
              maxWidth: "min(calc(100% - 60px), max(80vh, 300px))",
              marginBottom: 30,
            }}
          />
        </>
      )}

      <h1
        css={{
          ...(mode === "standard" ? theme.h1 : theme.h3),
          fontWeight: mode === "logo" && 800,
          margin: "0 auto",
          maxWidth: 600,
        }}
      >
        {preventWidows(title, mode === "standard" ? 9 : 12)}
      </h1>

      {mode === "standard" && copy && (
        <p css={{ margin: "24px auto 0", maxWidth: 400 }}>{copy}</p>
      )}

      {children}
    </div>
  </section>
)

export default HeroLego

HeroLego.propTypes = {
  mode: PropTypes.oneOf(["standard", "logo"]).isRequired,
  title: PropTypes.string.isRequired,
  copy: PropTypes.string,
  background: imageProptypes.isRequired,
  children: PropTypes.node,
}

export const query = graphql`
  fragment HeroLego on SanityHeroLego {
    mode
    title
    copy
    background {
      ...ImageWithPreview
    }
  }
`
